import React from 'react'

const Inside_Title = (props) => {
  return (
    <div>
      <div className="heading">
        <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m512 226.528a12 12 0 0 1 -12 12h-113.16l-23.7 29.846a12 12 0 0 1 -19.74-1.374l-22.88-38.837-30.75 135.454a12 12 0 0 1 -11.7 9.344q-.12 0-.24 0a12 12 0 0 1 -11.561-9.805l-32.061-172.556-45.077 98.82a12 12 0 0 1 -21.618.451l-24.748-48.758h-130.765a12 12 0 0 1 0-24h138.13a12 12 0 0 1 10.7 6.569l16.805 33.107 50.509-110.732a12 12 0 0 1 22.715 2.788l28.384 152.748 24.569-108.227a12 12 0 0 1 22.041-3.434l29.358 49.833 16.436-20.7a12 12 0 0 1 9.4-4.537h118.953a12 12 0 0 1 12 12z" /></svg>
        <h2>{props.title}</h2>
        <svg id="Layer_1" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg" data-name="Layer 1"><path d="m512 226.528a12 12 0 0 1 -12 12h-113.16l-23.7 29.846a12 12 0 0 1 -19.74-1.374l-22.88-38.837-30.75 135.454a12 12 0 0 1 -11.7 9.344q-.12 0-.24 0a12 12 0 0 1 -11.561-9.805l-32.061-172.556-45.077 98.82a12 12 0 0 1 -21.618.451l-24.748-48.758h-130.765a12 12 0 0 1 0-24h138.13a12 12 0 0 1 10.7 6.569l16.805 33.107 50.509-110.732a12 12 0 0 1 22.715 2.788l28.384 152.748 24.569-108.227a12 12 0 0 1 22.041-3.434l29.358 49.833 16.436-20.7a12 12 0 0 1 9.4-4.537h118.953a12 12 0 0 1 12 12z" /></svg>
      </div>
    </div>
  )
}

export default Inside_Title
