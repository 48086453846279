import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner';

const Achievement = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div>
      <Header />
      <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699382419/Akcent%20HealthCare/Banner/Achievement_vbv85r.png" title="Achievement" />
      <Footer />
    </div>
  )
}

export default Achievement
