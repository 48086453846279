import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Privacy Policy - Akcent Healthcare</title>
                <link rel="canonical" href="https://www.akcenthealthcare.com/" />
                <meta name="description" content="Safeguarding your trust in our pharmaceutical journey. Explore our Privacy Policy, a commitment to secure, transparent practices. Your data's confidentiality is our priority in advancing health solutions." />
            </Helmet>
            <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700461406/Akcent%20HealthCare/Banner/privacy_policy_banner_w5xgs8.png" title="privacy policy" />
            <div className="privacy-policy">

                <p>Akcent Healthcare Pvt Ltd is committed to protecting the privacy of our website users. This Privacy Policy outlines the types of personal information we collect, how it is used, and the steps we take to ensure its security.</p>

                <h3>Information Collected</h3>

                <h4>Personal Information:</h4>
                <p>We may collect personal information such as names, contact details, or addresses when voluntarily provided by users through online forms or registrations.</p>

                <h4>Non-Personal Information:</h4>
                <p>We automatically collect non-personal information, such as IP addresses, browser types, and device information, to enhance user experience and analyze website usage.</p>

                <h3>Use of Information:</h3>

                <h4>Improving User Experience:</h4>
                <p>Personal information is used to personalize user experiences and improve our website's functionality.</p>

                <h4>Communication:</h4>
                <p>We may use contact information to respond to inquiries, provide updates, and communicate about our products and services.</p>

                <h4>Analytics:</h4>
                <p>Non-personal information is utilized for website analytics, helping us understand user preferences, demographics, and trends.</p>

                <h3>Information Sharing</h3>

                <h4>Third-Party Service Providers:</h4>
                <p>We may share information with trusted third-party service providers to assist in website management, analytics, and communication.</p>

                <h4>Legal Compliance:</h4>
                <p>We may disclose information to comply with legal obligations, enforce our policies, or protect the rights, property, or safety of [Your Company Name] and its users.</p>

                <h3>Security Measures</h3>
                <p>We implement industry-standard security measures to safeguard personal information from unauthorized access, disclosure, alteration, and destruction.</p>

                <h3>Cookies</h3>
                <p>Cookies are used to enhance user experience and gather information about website usage. Users can manage cookie preferences through their browser settings.</p>

                <h3>Consent</h3>
                <p>By using our website, users consent to the terms outlined in this Privacy Policy.</p>

                <h3>Updates</h3>
                <p>We reserve the right to update and modify this Privacy Policy. Users are encouraged to review this page periodically for any changes.</p>

                <h3>Contact Information</h3>
                <p>For questions or concerns regarding our Privacy Policy, please contact us at <a style={{ color: '#f58432' }} href="mailto:akcenthealthcare@gmail.com">akcenthealthcare@gmail.com</a> </p>
                <p>Akcent Healthcare Pvt Ltd is dedicated to maintaining the highest standards of privacy protection. This policy ensures transparency and trust between our users and us.</p>

            </div>
            <Footer />
        </div>
    )
}

export default PrivacyPolicy
