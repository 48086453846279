import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import fb from "../../Firebase/firebase";
import AdminDashboardNav from "../AdminDashboardNav";
import { useNavigate } from 'react-router-dom';
const DB = fb.firestore()
const Brandslist = DB.collection('Brands');

const EditBrands = () => {
    const [b, SetB] = useState([]);
    let navigate = useNavigate();
    let id;

    useEffect(() => {
        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/edit-brands');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });

        const unsubscribe = Brandslist.limit(100).onSnapshot(querySnapshot => {
            // Get all documents from collection - with IDs
            const data = querySnapshot.docs.map(doc => ({
                ...doc.data(),
                id: doc.id,
            }));
            // Update state
            SetB(data);
        });

        // Detach listener
        return unsubscribe;
    }, []);

    const Deletebrand = (id) => {
        Brandslist.doc(id).delete().then(() => {
            console.log("Brand successfully deleted!");
        }).catch((error) => {
            console.error("Error removing document: ", error);
        });
    };
    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app'>
                    <h3>Edit Brands</h3>
                    <div className="blog-list brand-list">
                        {b.map(brand => (
                            <div className="blog-card" key={brand.id}>
                                <img src={brand.Image} alt="brand Image" />

                                <div className="blog-card-button">
                                    <button
                                        onClick={() => { Deletebrand(brand.id) }}
                                    >delete</button>
                                </div>

                            </div>
                        ))}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default EditBrands;