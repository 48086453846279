import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const InvestmentArea = () => {
  return (
    <div>
       <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701851075/Akcent%20HealthCare/Banner/investment_area_banner_j1qsd8.png" title="Investment Area" />
            <Footer />
    </div>
  )
}

export default InvestmentArea
