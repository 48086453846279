import React, { useEffect, useState } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import { Link } from 'react-router-dom'
import BCategory from '../Utilities/BCategory';
import LatestPosts from '../Utilities/LatestPosts';
import Banner from '../Utilities/Banner';
import fb from "../Firebase/firebase";
import { Helmet } from "react-helmet";
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs');

const Blog = () => {

  const [blogs, Setblogs] = useState([]);

  useEffect(() => {
    // 👇️ scroll to top on page load

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

    const unsubscribe = Blogslist.limit(100).onSnapshot(querySnapshot => {
      // Get all documents from collection - with IDs
      const data = querySnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
      }));
      // Update state
      Setblogs(data);
    });

    // Detach listener
    return unsubscribe;
  }, []);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs - Akcent Healthcare</title>
        <link rel="canonical" href="https://www.akcenthealthcare.com/" />
        <meta name="description" content="Explore insights at the intersection of science and wellness on our pharmaceutical blog. Stay informed about industry trends, breakthroughs, and health perspectives. Your source for informed healthcare discussions." />
      </Helmet>
      <Header />
      <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699382420/Akcent%20HealthCare/Banner/Blog_fom6ea.png" title="Blogs" alt="A stethoscope is placed on laptio with medical health logo" />
      <div className="blog">
        <div className="blog-body">
          <div className="blog-body-content">
            {blogs.map(blog => (
              <div className="blog-card">
                <img src={blog.Image} alt="" />
                <div className="blog-card-text">
                  <span className='date'>{blog.Date}</span>
                  <h3><Link to={"/show/" + blog.id}>{blog.Title}</Link></h3>
                </div>
              </div>
            ))}

          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default Blog
