import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const Gallery = () => {
  return (
    <div>
      <Header />
      <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701851075/Akcent%20HealthCare/Banner/Gallery_banner_foos1o.png" title="Gallery" />

      <div className="gallery-buttons">
        <button>All</button>
        <button>button 2</button>
        <button>button 3</button>
        <button>button 4</button>
      </div>

      <div class="album">
        <div class="responsive-container-block bg">
          <div class="responsive-container-block img-cont">
            <img class="img" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.4.svg" />
            <img class="img" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.5.svg" />
            <img class="img img-last" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.6.svg" />
          </div>
          <div class="responsive-container-block img-cont">
            <img class="img img-big" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.11.svg" />
            <img class="img img-big img-last" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.10.svg" />
          </div>
          <div class="responsive-container-block img-cont">
            <img class="img" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.7.svg" />
            <img class="img" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.8.svg" />
            <img class="img" src="https://workik-widget-assets.s3.amazonaws.com/widget-assets/images/PP5.9.svg" />
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}

export default Gallery
