import React, { useState } from 'react'
import { Link } from 'react-router-dom'

const Header = () => {

    const [navBg, setNavBg] = useState("#fff");
    const [top, setTop] = useState("0");
    const [width, setWidth] = useState("100%");
    const [transform, setTransform] = useState("none");
    const [borderRadius, setBorderRadius] = useState("0");
    const [display, setDisplay] = useState("none");

    const show = () => {
        setDisplay('flex');
    }

    const hide = () => {
        setDisplay('none');
    }




    return (

        <div className='navbar' style={{ background: navBg, top: top, width: width, borderRadius: borderRadius, transform: transform }}>
            <div className='header' >
                <div className="header-logo">
                    <Link to={"/"}>  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699011213/Akcent%20HealthCare/Logo/logo_transparent_i0ueir.png" alt="logo" /></Link>
                </div>
                <div className="header-links">
                    <Link to={"/"}>Home </Link>
                    <Link to={"/about-us"}>About Us </Link>
                    <Link to={"/brand-portfolio"}>Brand Portfolio</Link>
                    {/* <Link to={"/"}>Achievement</Link>
                    <Link to={"/"}>Life At Akcent </Link> */}
                    {/* <div className="dropdown">
                        <Link to={"/about-us"}>About Us </Link>
                        <div className="dropdown-content">
                            <Link to={"/brand-portfolio"}>Brand Portfolio </Link>
                            <Link to={"/subsidiary"}>Subsidiary </Link>
                        </div>
                    </div>
                    <div className="dropdown" style={{ marginLeft: '-5%' }}>
                        <Link to={"/about-us"}>Life At Akcent</Link>
                        <div className="dropdown-content">
                            <Link to={"/gallery"}>Gallery</Link>
                            <Link to={"/career"}>Career</Link>
                        </div>
                    </div>
                    <Link to={"/"} style={{ marginLeft: '-2%' }}>Investment Area</Link>
                    <div className="dropdown">
                        <Link to={"/news-room"}>News Room</Link>
                        <div className="dropdown-content">
                            <Link to={"/press-releasr"}>Press Release</Link>
                            <Link to={"/news"}>News</Link>
                            <Link to={"/blog"}>Blog</Link>
                            <Link to={"/achievement"}>Achievement</Link>
                        </div>
                    </div> */}
                    <Link to={"/career"}>Career </Link>
                    <Link to={"/blog"}>Blog</Link>
                    <Link to={"/contact-us"} >Contact Us </Link>
                    {/* <Link to={"/shop-now"}>Shop Now </Link> */}
                </div>
                <svg className='menu' onClick={show} id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m464.883 64.267h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m47.134 64.267c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 208.867c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 353.467c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m464.883 208.867h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m464.883 353.467h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.012-21.136-47.149-47.116-47.149z" /></svg>

                <div className="header-mobile" style={{ display: display }}>
                    <svg onClick={hide} className='cross' id="Layer_2_00000179618821480493002380000007792386261343661476_" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="_38" d="m358.5 153.5c9.8 9.8 9.8 25.6 0 35.4l-67.2 67.1 67.2 67.2c9.8 9.8 9.8 25.6 0 35.4-9.8 9.8-25.6 9.8-35.4 0l-67.1-67.2-67.2 67.2c-9.8 9.8-25.6 9.8-35.4 0-9.8-9.8-9.8-25.6 0-35.4l67.2-67.2-67.2-67.2c-9.8-9.8-9.8-25.6 0-35.4 9.8-9.8 25.6-9.8 35.4 0l67.2 67.2 67.2-67.2c9.7-9.7 25.6-9.7 35.3.1z" /></g></svg>
                    <div className="header-mobile-content">
                        <Link to={"/"}>Home </Link>
                        <Link to={"/about-us"}>About Us </Link>
                        <Link to={"/brand-portfolio"}>Brand Portfolio</Link>
                        {/* <Link to={"/"}>Achievement</Link>
                        <Link to={"/"}>Life At Akcent </Link> */}
                        <Link to={"/career"}>Career </Link>
                        <Link to={"/blog"}>Blog</Link>
                        <Link to={"/contact-us"}>Contact Us </Link>
                    </div>

                </div>
            </div>

        </div>
    )
}

export default Header
