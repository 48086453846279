import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const News = () => {
  return (
    <div>
       <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701853503/Akcent%20HealthCare/Banner/news_banner_vfqtr9.png" title="News" />
            <Footer />
    </div>
  )
}

export default News
