import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const Subsidiary = () => {
    return (
        <div>
            <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701851128/Akcent%20HealthCare/Banner/Subsidiary_banner_b1tq7z.png" title="Subsidiary" />
            <Footer />
        </div>
    )
}

export default Subsidiary
