import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'
import Title from '../Utilities/Title'
import { useState } from 'react'
import { Helmet } from "react-helmet";

const BrandPortfolio = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    const href = window.location.href.substring(
      window.location.href.lastIndexOf('#') + 1
    );
    const element = document.getElementById(href);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  }, []);


  const [d1, setD1] = useState(false);
  const [d2, setD2] = useState(false);
  const [d3, setD3] = useState(false);
  const [d4, setD4] = useState(false);
  const [d5, setD5] = useState(false);
  const [d6, setD6] = useState(false);
  const [d7, setD7] = useState(false);
  const [d8, setD8] = useState(false);
  const [d9, setD9] = useState(false);
  const [d10, setD10] = useState(false);
  const [d11, setD11] = useState(false);


  const Cardiovascular = () => {
    setD1(current => !current);
  }
  const Neurology = () => {
    setD2(current => !current);
  }
  const orthopedics = () => {
    setD3(current => !current);
  }

  const oncology = () => {
    setD4(current => !current);
  }
  const surgery = () => {
    setD5(current => !current);
  }
  const Gynaecology = () => {
    setD6(current => !current);
  }
  const ENT = () => {
    setD7(current => !current);
  }
  const Nephrology = () => {
    setD8(current => !current);
  }

  const Urology = () => {
    setD9(current => !current);
  }
  const Physician = () => {
    setD10(current => !current);
  }

  const Gastroenterology = () => {
    setD11(current => !current);
  }


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Brand Portfolio - Akcent Healthcare</title>
        <link rel="canonical" href="https://www.akcenthealthcare.com/" />
        <meta name="description" content="Explore our diverse brand portfolio encompassing a spectrum of pharmaceutical specialties - Cardiovascular, Neurology, Orthopedics, Oncology, Surgery, Gynaecology, ENT, Nephrology, Urology, Physician, and Gastroenterology." />
      </Helmet>
      <Header />
      <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699382420/Akcent%20HealthCare/Banner/Brand_Portfolio_fv55ah.png" title="Brand Portfolio" />

      <div className="b-p">
        <div className="b-p-card cardiovascular" >
          <div className="b-p-body">
            <h3 id='cardiovascular'>Cardiovascular</h3>
            <p>Cardiovascular agents are medications used to treat disorders of the
              heart or blood vessels that are part of the circulatory system. These
              disorders include arrhythmias, blood clots, coronary artery disease,
              hypertension, hypotension, hypercholesterolemia, heart failure, and
              stroke. Our goal is to prevent and reverse the progression of
              cardiovascular illnesses because we think that treating them only will
              not be enough in the future.</p>
            <button onClick={Cardiovascular}>Read More</button>
          </div>
          {d1 && (
            <div className="b-p-brand">
              <h6>B-MARCO</h6>
              <h6>VIGANEX 20</h6>
              <h6>WELLTOO PRO</h6>
              <h6>AKCEMAX</h6>
              <h6>MURINE 300</h6>
              <h6>RASTROL 10</h6>
              <h6>VEINOSIL-FORTE</h6>
              <h6>BETKAS-N3</h6>
              <h6>CALNANO 60K</h6>
              <h6>MURINE-XT</h6>
              <h6>MURINE-100</h6>
              <h6>Q MARCO 300</h6>
              <h6>RASTROL 20</h6>
              <h6>RASTROL 40</h6>
              <h6>RASTROL-F10</h6>
              <h6>RASTROL-F20</h6>
              <h6>RASTROL GOLD</h6>
              <h6>AXCELTO-2.5</h6>
              <h6>AXCELTO-20</h6>
              <h6>AVATROL</h6>
              <h6>AKCERON-OD</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card neurology" >
          <div className="b-p-body">
            <h3 id='neurology'>Neurology</h3>
            <p>The primary cause of the rising incidence of non-communicable
              neurological illnesses in India is due to the aging population. High
              blood pressure, air pollution, dietary hazards, high fasting plasma
              glucose, and a high body mass index are among the known risk
              factors for neurological diseases.
            </p>
            <p>In India, the most common neurological conditions are:</p>

            <ul>

              <li> Stroke</li>
              <li> seizures</li>
              <li> Cerebral palsy</li>
              <li> Alzheimer's disease</li>
              <li> Parkinson's disease</li>
              <li> Multiple sclerosis</li>
            </ul>
            <button onClick={Neurology}>Read More</button>

          </div>
          {d2 && (
            <div className="b-p-brand">
              <h6>AKCIT-500</h6>
              <h6>B-MARCO</h6>
              <h6>VIGANEX 20</h6>
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>AKSAM 400</h6>
              <h6>BETKAS-N3</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>CALNANO 60K</h6>
              <h6>LEANTOR</h6>
              <h6>MURINE-XT</h6>
              <h6>MURINE-100</h6>
              <h6>Q MARCO 300</h6>
              <h6>XGABA NT 100</h6>
              <h6>XGABA NT 400</h6>
              <h6>AXCELTO-2.5</h6>
              <h6>AXCELTO-20</h6>
              <h6>AVATROL</h6>
              <h6>AKCERON-OD</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card orthopedics" >
          <div className="b-p-body">
            <h3 id='orthopedics'>orthopedics</h3>
            <p>Since its founding,Akcent has provided the orthopedic segment with
              high-quality, reasonably priced, and technologically advanced
              products.</p>
            <p>Our vast Orthopedic portfolio offers NSAIDs,Calcium formulations,
              Vitamin-D preparations,and Anti-gout preparations.
            </p>
            <button onClick={orthopedics}>Read More</button>

          </div>
          {d3 && (
            <div className="b-p-brand">
              <h6>B-MARCO</h6>
              <h6>IMORE GEL</h6>
              <h6>WELLTOO PRO</h6>
              <h6>COLLARED</h6>
              <h6>MURINE 300</h6>
              <h6>AKSAM 400</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>CALNANO 60K</h6>
              <h6>HEALTHIRON CAP</h6>
              <h6>MURINE-XT</h6>
              <h6>TRIMYOU</h6>
              <h6>XGABA NT 100</h6>
              <h6>XGABA NT 400</h6>
              <h6>AXCELTO-2.5</h6>
              <h6>AXCELTO-20</h6>
              <h6>AVATROL</h6>
              <h6>AKCERON-OD</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card oncology" >
          <div className="b-p-body">
            <h3 id='oncology'>oncology</h3>
            <p>Oncology is the branch of medicine that focuses on the diagnosis,
              treatment, and prevention of cancer.The primary goal of oncology is
              to understand and combat the complex and diverse group of diseases
              collectively known as cancer.The field of oncology presents a
              significant and growing market for pharmaceutical companies. As our
              understanding of cancer biology and treatment options continues to
              expand, so does the demand for innovative oncology medicines</p>
            <button onClick={oncology}>Read More</button>

          </div>
          {d4 && (
            <div className="b-p-brand">
              <h6>AKCIT-500</h6>
              <h6>B-MARCO</h6>
              <h6>VIGANEX 20</h6>
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>AKSAM 400</h6>
              <h6>BETKAS-N3</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>CALNANO 60K</h6>
              <h6>LEANTOR</h6>
              <h6>MURINE-XT</h6>
              <h6>MURINE-100</h6>
              <h6>Q MARCO 300</h6>
              <h6>XGABA NT 100</h6>
              <h6>XGABA NT 400</h6>
              <h6>AXCELTO-2.5</h6>
              <h6>AXCELTO-20</h6>
              <h6>AVATROL</h6>
              <h6>AKCERON-OD</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card surgery" >
          <div className="b-p-body">
            <h3 id='surgery'>surgery</h3>
            <p>Akcent is dedicated to supporting the surgery segment and has a
              broad range of products that have benefited millions of people in
              India with the help of our premium and reasonably priced, highly
              promising PPI range, NSAIDs, pre- and post-surgical care, and nerve
              regenerators.</p>
            <button onClick={surgery}>Read More</button>

          </div>
          {d5 && (
            <div className="b-p-brand">
              <h6>WELLTOO PRO</h6>
              <h6>B-MARCO</h6>
              <h6>MURINE 300</h6>
              <h6>MURINE-XT</h6>
              <h6>RUTACE-DS</h6>
              <h6>XGABA NT 100</h6>
              <h6>XGABA NT 400</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card gynecology" >
          <div className="b-p-body">
            <h3 id='gynecology'>Gynaecology</h3>
            <p>Gynecology is a subspecialty of medicine that focuses on problems
              related to women's health, especially those pertaining to the
              reproductive system. Akcent is committed to provide women with
              reproductive problems that lead to a good birth rate with its
              innovative treatments and up-to-date medication selection.</p>
            <button onClick={Gynaecology}>Read More</button>

          </div>
          {d6 && (
            <div className="b-p-brand">
              <h6>PROVIAM</h6>
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>MURINE-XT</h6>
              <h6>RUTACE-DS</h6>
              <h6>TRIMYOU</h6>
              <h6>AXCELTO-2.5</h6>
              <h6>AXCELTO-20</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card ent" >
          <div className="b-p-body">
            <h3 id='ent'>ENT</h3>
            <p>The field of Otolaryngology, commonly known as ENT (Ear, Nose, and
              Throat), encompasses a wide range of medical conditions related to the
              head and neck. This specialty deals with disorders and diseases affecting
              the ears, nose, throat, as well as adjacent areas like the head and
              neck.The scope of ENT medicines offers several business opportunities
              for pharmaceutical companies like us.</p>
            <button onClick={ENT}>Read More</button>

          </div>
          {d7 && (
            <div className="b-p-brand">
              <h6>MURINE 300</h6>
              <h6>MURINE-XT</h6>
              <h6>RUTACE-DS</h6>
            </div>
          )}
        </div>

        <div className="b-p-card nephrology" >
          <div className="b-p-body">
            <h3 id='nephrology'>Nephrology</h3>
            <p>Conditions that harm your kidneys and lessen their capacity to keep you
              healthy by filtering waste products out of your blood fall under the
              category of chronic kidney disease. 40–60% of CKD cases in India are
              caused by diabetes and hypertension. In 41% of cases, diabetic
              nephropathy was the most common cause of chronic kidney disease
              (CKD).
            </p>
            <p>With CKD, you could experience issues such as:</p>

            <ul>
              <li> Elevated blood pressure</li>
              <li> Low blood count, or anemia</li>
              <li> fragile bones</li>
              <li> Inadequate dietary intake</li>
              <li> Nerve injury</li>
            </ul>
            <button onClick={Nephrology}>Read More</button>

          </div>
          {d8 && (
            <div className="b-p-brand">
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>PROVIAM</h6>
              <h6>BETKAS-N3</h6>
              <h6>MURINE-XT</h6>
              <h6>CALLAXO-MAX</h6>
              <h6>VIDALA-50</h6>
              <h6>VIDALA-M50/500</h6>
            </div>
          )}
        </div>

        <div className="b-p-card urology" >
          <div className="b-p-body" >
            <h3 id='urology'>Urology</h3>
            <p>The scope of urology medicines for patients is significant and
              encompasses a wide range of conditions related to the urinary tract and
              male reproductive system. These medications play a crucial role in
              managing symptoms, treating underlying conditions, and improving
              overall quality of life.Continued research and innovation in this field are
              essential for developing new and improved medications to address the
              diverse needs of patients with urological disorders.</p>
            <button onClick={Urology}>Read More</button>

          </div>
          {d9 && (
            <div className="b-p-brand">
              <h6>VIGANEX 20</h6>
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>PROVIAM</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>MURINE-XT</h6>
              <h6>MURINE-100</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

        <div className="b-p-card physician" >
          <div className="b-p-body">
            <h3 id='physician'>Physician</h3>
            <p>General physicians, also known as primary care physicians or family
              doctors, are trained to diagnose, treat, and manage a wide range of
              medical conditions. They serve as the first point of contact for patients
              seeking healthcare and are capable of addressing a broad spectrum of
              diseases and health concerns</p>
            <button onClick={Physician}>Read More</button>

          </div>
          {d10 && (
            <div className="b-p-brand">
              <h6>B-MARCO</h6>
              <h6>IMORE GEL</h6>
              <h6>VIGANEX 20</h6>
              <h6>WELLTOO PRO</h6>
              <h6>MURINE 300</h6>
              <h6>AKCECAL-FORTE</h6>
              <h6>RASTROL 10</h6>
              <h6>AKSAM 400</h6>
              <h6>BETKAS-N3</h6>
              <h6>CALLAXO-PLUS</h6>
              <h6>CALNANO 60K</h6>
              <h6>LEANTOR</h6>
              <h6>MURINE-XT</h6>
              <h6>RASTROL 20</h6>
              <h6>RASTROL 40</h6>
              <h6>RASTROL-F10</h6>
              <h6>RASTROL-F20</h6>
              <h6>TRIMYOU</h6>
              <h6>VIDALA-M50/500</h6>
              <h6>GUTAMVI-FORTE</h6>
              <h6>AVATROL</h6>
              <h6>AKCERON-OD</h6>
              <h6>CALLAXO-MAX</h6>
              <h6>VIDALA-50</h6>
            </div>
          )}
        </div>

        <div className="b-p-card gastroenterology" >
          <div className="b-p-body">
            <h3 id='gastroenterology'>Gastroenterology</h3>
            <p>Akcent has been a well-known name in the gastroenterology field. Our
              areas of expertise are providing treatment options for the treatment of
              acid-peptic disorders, hepatoprotection, and irritable bowel syndrome
              (IBS).Our products offers anxiolytic, antispasmodic, and anti-secretory
              benefits for all-encompassing IBS management.</p>
            <button onClick={Gastroenterology}>Read More</button>

          </div>
          {d11 && (
            <div className="b-p-brand">
              <h6>WELLTOO PRO</h6>
              <h6>PROVIAM</h6>
              <h6>AKSAM 400</h6>
              <h6>LEANTOR</h6>
              <h6>MURINE-XT</h6>
              <h6>TRIMYOU</h6>
              <h6>WELLTOO</h6>
              <h6>CALLAXO-MAX</h6>
            </div>
          )}
        </div>

      </div>
      <Footer />
    </div >
  )
}

export default BrandPortfolio
