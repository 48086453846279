import "./Components/Sass/main.scss"
import "./Components/Sass/media.scss"
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Home from "./Components/Pages/Home"
import AboutUs from "./Components/Pages/AboutUs"
import Achievement from "./Components/Pages/Achievement"
import Blog from "./Components/Pages/Blog"
import BlogDetails from "./Components/Pages/BlogDetails"
import BrandPortfolio from "./Components/Pages/BrandPortfolio"
import Career from "./Components/Pages/Career"
import ContactUs from "./Components/Pages/ContactUs"
import LifeAtAkcent from "./Components/Pages/LifeAtAkcent"
import CreateBlog from './Components/AdminPanel/Blog/create'
import ResetPassword from './Components/AdminPanel/ResetPassword'
import BlogslistView from './Components/AdminPanel/Blog/blogslist'
import Login from './Components/AdminPanel/Login'
import Admin from './Components/AdminPanel/Admin'
import ContactDB from './Components/AdminPanel/ContactDB'
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy"
import BMarco from "./Components/AdminPanel/Products/BMarco"
import CareerList from "./Components/AdminPanel/Career/CareerList"
import PostJob from "./Components/AdminPanel/Career/PostJob"
import News from './Components/Pages/News'
import PressRelease from './Components/Pages/PressRelease'
import Subsidiary from './Components/Pages/Subsidiary'
import InvestmentArea from './Components/Pages/InvestmentArea'
import ShopNow from './Components/Pages/ShopNow'
import Gallery from './Components/Pages/Gallery'
import BrandUpload from "./Components/AdminPanel/Brand/BrandUpload"
import EditBrands from "./Components/AdminPanel/Brand/EditBrands"


function App() {
  return (
    <div>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/achievement" element={<Achievement />} />
            <Route path="/brand-portfolio" element={<BrandPortfolio />} />
            <Route path="/career" element={<Career />} />
            <Route path="/contact-us" element={<ContactUs />} />
            <Route path="/life-at-akcent" element={<LifeAtAkcent />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Admin */}
            <Route path="/brand-upload" element={<BrandUpload />} />
            <Route path="/edit-brands" element={<EditBrands />} />



            {/* blog */}
            <Route path="/bloglist" element={<BlogslistView />} />
            <Route path="/create/" element={<CreateBlog />} />
            <Route path="/show/:id" element={<BlogDetails />} />
            <Route path='/blog' element={<Blog />} />


            {/* Admin */}
            <Route exact path="/admin-login" element={<Login />} />
            <Route exact path="/admin" element={<Admin />} />
            <Route exact path="/contact-form-database" element={<ContactDB />} />
            <Route exact path="/admin-reset-password" element={<ResetPassword />} />

            {/* Career */}
            <Route exact path="/view-job" element={<CareerList />} />
            <Route exact path="/post-job" element={<PostJob />} />

            {/* Products */}
            <Route exact path="/b-marco" element={<BMarco />} />

            {/* About */}
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/news" element={<News />} />
            <Route exact path="/press-release" element={<PressRelease />} />
            <Route exact path="/subsidiary" element={<Subsidiary />} />
            <Route exact path="/investment-area" element={<InvestmentArea />} />
            <Route exact path="/shop-now" element={<ShopNow />} />


          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
