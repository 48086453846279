import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import fb from "../../Firebase/firebase";
import AdminDashboardNav from "../AdminDashboardNav";
import "firebase/compat/storage";
import { getDownloadURL, ref as storageRef, uploadBytes } from "firebase/storage";
import { v4 as uuid } from "uuid";
const DB = fb.firestore()
const Blogslist = DB.collection('Brands');
const storage = fb.storage();

const BrandUpload = () => {
    let navigate = useNavigate();
    let id;

    const sub = () => {
        setTimeout(() => {
            submit();
        }, 1000);
    }

    useEffect(() => {

        fb.auth().onAuthStateChanged(function (user) {
            if (user) {
                id = user.uid;
                navigate('/brand-upload');
                console.log("uid", id);
            } else {
                navigate('/admin-login');
                console.log("user is logged out")
            }
        });
    }, [])

    const submit = async (e) => {
        e.preventDefault();
        if (imageUpload === null) {
            alert("Please select an image");
        }
        else {

            if (imageUpload.size > 1e6) {
                alert("Please upload a file smaller than 1 MB");
                return false;
            }
            uploadBytes(imageRef, imageUpload)
                .then((snapshot) => {
                    getDownloadURL(snapshot.ref)
                        .then((url) => {
                            setImageURL(url);
                        })
                        .catch((error) => {
                            alert(error.message);
                        });
                })
                .catch((error) => {
                    alert(error.message);
                });

            Blogslist.add({
                Image: imageURL
            }).then((docRef) => {
                alert('Brand Updated Successfully')
            }).catch((error) => {
                console.error("error:", error);
            });


        }
    }

    const [imageUpload, setImageUpload] = useState(null);//uploading image
    const uniqueId = uuid();//creating a unique id
    const imageRef = storageRef(storage, `${uniqueId}`);//storing image in firebase storage with a unique id
    const [imageURL, setImageURL] = useState('');// retrieving URL

    return (
        <div>
            <div className='dashboard'>
                <AdminDashboardNav />
                <div className='dashboard-app'>
                    <h3>Upload Brands</h3>
                    <div className="blog-create">
                        <form method='POST' onSubmit={submit}>
                            <div className="form-field">
                                <div className="__input">
                                    <label htmlFor="">Upload Image</label>
                                    <div className="b__input">
                                        <span>* File Should be in .png and .jpg format only, Size should be less then 1 Mb</span>
                                        <input
                                            label="Image"
                                            placeholder="Choose image"
                                            accept="image/png,image/jpg"
                                            type="file"
                                            multiple
                                            onChange={(e) => {
                                                setImageUpload(e.target.files[0]);
                                            }}
                                            required
                                        />
                                    </div>

                                </div>
                                <button type="submit" onClick={sub}>Submit</button>
                            </div>
                        </form>

                    </div>
                </div>
            </div>

        </div>
    );
};

export default BrandUpload;