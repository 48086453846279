import React, { useEffect, useState, useRef } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'
import emailjs from '@emailjs/browser';
import fb from "../Firebase/firebase";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from "react-helmet";
const DB = fb.firestore()
const CF = DB.collection('Contact_Form');

const ContactUs = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const form = useRef();
  const [userData, setUserData] = useState({
    Name: "",
    Email: "",
    Mobile: "",
    Message: ""

  });
  let name, value;
  const postUserData = (e) => {
    name = e.target.name;
    value = e.target.value;
    setUserData({ ...userData, [name]: value });
  }

  //connect with firebase
  const SubmitData = async (e) => {
    e.preventDefault();
    const { Name, Email, Mobile, Message, Subject } = userData;
    if (Name && Email && Mobile && Message && Subject) {


      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
      if (!emailRegex.test(userData.Email)) {
        toast.warning('Please enter a valid email address');
        return
      }

      if (typeof Mobile !== "undefined") {

        var pattern = new RegExp(/^[0-9\b]+$/);

        if (!pattern.test(userData.Mobile)) {
          toast.warning("Please enter only number.");
          return

        } else if (userData.Mobile.length !== 10) {
          toast.warning("Please enter 10 digit Mobile number.");
          return
        }
      }

      try {

        CF.add({
          Name: Name,
          Email: Email,
          Mobile: Mobile,
          Subject: Subject,
          Message: Message
        }).then((docRef) => {
          toast.success('Message Sent Successfully');
        }).catch((error) => {
          console.error("error:", error);
        });

        emailjs.sendForm('service_91sqknx', 'template_sbd2ugv', form.current, 'Gsel6kHV1nHKBLMQM')
          .then((result) => {
            console.log(result.text);

          }, (error) => {
            console.log(error.text);
          });

        setUserData({
          Name: "",
          Email: "",
          Mobile: "",
          Message: "",
          Subject: ""
        })

        // console.log("Document written with ID: ", docRef.id);
      } catch (e) {
        console.error("Error adding document: ", e);
        toast.warning("Error adding document")
      }
    }
    else {
      toast.warning('Please fill the data');
    }
  };


  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us - Akcent Healthcare</title>
        <link rel="canonical" href="https://www.akcenthealthcare.com/" />
        <meta name="description" content="Connect with our pharmaceutical experts. Reach out for inquiries, partnerships, or consultations. Your gateway to a healthier collaboration. Contact us today for a seamless pharmaceutical experience" />
      </Helmet>
      <ToastContainer />
      <Header />
      <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699428159/Akcent%20HealthCare/Banner/contact_us_ge6sid.png" title="Contact Us" />

      <div className="contact">
        <div className="contact-info">
          <div className="contact-info-card">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
            <a href="tel:911145009554">+91-1145009554</a>
          </div>
          <div className="contact-info-card">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z" /></svg>
            <a href="mailto:akcenthealthcare@gmail.com" style={{ textTransform: 'lowercase' }}>akcenthealthcare@gmail.com</a>
          </div>
          <div className="contact-info-card">
            <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
            <a href="https://www.google.com/maps?ll=28.649983,77.145366&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">7/27 Kriti Nagar Industrial Area New Delhi-110015</a>
          </div>
        </div>
        <div className="contact-form">
          <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699385195/Akcent%20HealthCare/Contact/contact-form-bg_lpfp9e.png" alt="" />
          <form action="POST" ref={form}>
            <h2>get in touch</h2>
            <input type="text"
              placeholder='Your Name'
              name='Name'
              value={userData.Name}
              onChange={postUserData} />
            <input type="email"
              placeholder='Your Email'
              name='Email'
              value={userData.Email}
              onChange={postUserData} />
            <input type="text"
              placeholder='Contact Number'
              name='Mobile'
              value={userData.Mobile}
              onChange={postUserData} />
            <input type="text"
              placeholder='Subject'
              name='Subject'
              value={userData.Subject}
              onChange={postUserData} />
            <textarea
              cols="30"
              rows="3"
              name='Message'
              placeholder='Your Message'
              value={userData.Message}
              onChange={postUserData}>
            </textarea>
            <button onClick={SubmitData}>Send</button>
          </form>
        </div>

        <div className="contact-map">
          <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7002.650195461083!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1699012440986!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        </div>
      </div>
      <Footer />
    </div>
  )
}

export default ContactUs
