import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const PressRelease = () => {
    return (
        <div>
            <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701851127/Akcent%20HealthCare/Banner/Press_Release_Banner_dmeolj.png" title="Press Release" />
            <Footer />
        </div>
    )
}

export default PressRelease
