import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {

  const up = () => {

    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <div>
      <footer>
        <div className="footer-body">
          <div className="footer-about">
            <h2>Akcent Healthcare</h2>
            <p>Akcent Healthcare was founded out of passion in the year 2019, based
              in the heart of India (Delhi) the capital of India by our founders to be
              more than a business firm that aims at profit. However, it actively
              started working as a fully integrated pharmaceutical company in 2019z.
              Today, we have more than 100 products and are heading towards a
              turnover of 200 million. Our primary center lies in creating the finest
              quality item. We have a very long list which incorporates items such as
              Softgels, Capsules, Tablets, Protein Powder, Syrups Antibiotics, etc.</p>


          </div>
          <div className="footer-links">
            <h4>useful Links</h4>
            <div className="footer-links-content">
              <Link onClick={up} to={"/"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Home</Link>
              <Link onClick={up} to={"/about-us"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;About Us</Link>
              <Link onClick={up} to={"/brand-portfolio"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Brand Portfolio</Link>
              {/* <Link onClick={up} to={"/"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Achievement</Link>
              <Link onClick={up} to={"/life-at-akcent"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;life at Akcent</Link> */}

              <Link onClick={up} to={"/career"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Career</Link>

              <Link onClick={up} to={"/blog"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Blog</Link>
              <Link onClick={up} to={"/contact-us"}><svg id="Capa_1" enable-background="new 0 0 512.02 512.02" height="512" viewBox="0 0 512.02 512.02" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Drug"><g><path d="m300 192.003c-11.028 0-20-8.972-20-20v-23.44c23.278-8.256 40-30.487 40-56.56v-32c0-33.084-26.916-60-60-60h-160c-33.084 0-60 26.916-60 60v32c0 26.073 16.722 48.303 40 56.56v23.44c0 11.028-8.972 20-20 20-33.084 0-60 26.916-60 60v200c0 33.084 26.916 60 60 60h202.077c11.046 0 20-8.954 20-20s-8.955-20-20-20h-202.077c-11.028 0-20-8.972-20-20v-200c0-11.028 8.972-20 20-20 33.084 0 60-26.916 60-60v-20h120v20c0 33.084 26.916 60 60 60 11.028 0 20 8.972 20 20v65.539c0 11.046 8.954 20 20 20s20-8.954 20-20v-65.539c0-33.085-26.916-60-60-60zm-220-132c0-11.028 8.972-20 20-20h160c11.028 0 20 8.972 20 20v32c0 11.028-8.972 20-20 20h-160c-11.028 0-20-8.972-20-20z" /><path d="m180 392.003c11.046 0 20-8.954 20-20v-20h20c11.046 0 20-8.954 20-20s-8.954-20-20-20h-20v-20c0-11.046-8.954-20-20-20s-20 8.954-20 20v20h-20c-11.046 0-20 8.954-20 20s8.954 20 20 20h20v20c0 11.045 8.954 20 20 20z" /></g><path d="m494.475 324.772c-23.385-23.386-61.468-23.387-84.852 0l-84.854 84.853c-23.393 23.394-23.393 61.458 0 84.853 23.387 23.386 61.468 23.388 84.853 0l84.854-84.853h.001c23.391-23.395 23.391-61.459-.002-84.853zm-113.138 141.421c-7.753 7.753-20.531 7.753-28.283 0-7.798-7.798-7.798-20.486-.001-28.284l28.285-28.284 28.284 28.284zm84.854-84.853-28.285 28.284-28.284-28.284 28.285-28.284c7.753-7.753 20.53-7.752 28.283 0 7.798 7.798 7.798 20.487.001 28.284z" /></g></svg>&emsp;Contact Us</Link>
            </div>
          </div>
          <div className="footer-contact">
            <h4>Contact info</h4>

            <div className="footer-contact-body">
              <div className="footer-contact-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M164.9 24.6c-7.7-18.6-28-28.5-47.4-23.2l-88 24C12.1 30.2 0 46 0 64C0 311.4 200.6 512 448 512c18 0 33.8-12.1 38.6-29.5l24-88c5.3-19.4-4.6-39.7-23.2-47.4l-96-40c-16.3-6.8-35.2-2.1-46.3 11.6L304.7 368C234.3 334.7 177.3 277.7 144 207.3L193.3 167c13.7-11.2 18.4-30 11.6-46.3l-40-96z" /></svg>
                <a href="tel:911145009554">+91-1145009554</a>
              </div>
              <div className="footer-contact-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M64 208.1L256 65.9 448 208.1v47.4L289.5 373c-9.7 7.2-21.4 11-33.5 11s-23.8-3.9-33.5-11L64 255.5V208.1zM256 0c-12.1 0-23.8 3.9-33.5 11L25.9 156.7C9.6 168.8 0 187.8 0 208.1V448c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V208.1c0-20.3-9.6-39.4-25.9-51.4L289.5 11C279.8 3.9 268.1 0 256 0z" /></svg>
                <a href="mailto:akcenthealthcare@gmail.com" style={{ textTransform: 'lowercase' }}>akcenthealthcare@gmail.com</a>
              </div>
              <div className="footer-contact-content">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" /></svg>
                <a target='_blank' href="https://www.google.com/maps?ll=28.649983,77.145366&z=15&t=m&hl=en&gl=IN&mapclient=embed&cid=6992852429798267520">7/27 Kriti Nagar Industrial Area New Delhi-110015</a>
              </div>
            </div>

            <div className="footer-follow">

              <a target="_blank" href="https://www.facebook.com/akcenthealthcaresales/">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 482.38 504 379.78 504 256z" /></svg>
              </a>

              <a target="_blank" href="https://twitter.com/AkcentHealthca1">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z" /></svg>              </a>

              <a target="_blank" href="https://www.linkedin.com/company/akcent-healthcare-india-pvt-ltd/about/">
                <svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 448 512"><path d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z" /></svg>
              </a>

            </div>

          </div>
          <div className="footer-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7002.650195461083!2d77.145366!3d28.649983000000002!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d033a653f51a9%3A0x610b9a3c34da5a80!2sAkcent%20Healthcare%20(INDIA)%20Pvt%20Ltd!5e0!3m2!1sen!2sin!4v1699012440986!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>

        <div className="footer-copyright">
          <p>© Copyright 2023. All Rights Reserved by <a target='_blank' href="https://www.akcenthealthcare.com/">Akcent Healthcare Pvt. Ltd.</a></p>
          <div className="footer-copyright-content">
            <Link onClick={up} to={"/privacy-policy"}>Privacy Policy</Link>
          </div>
        </div>
      </footer >
    </div >
  )
}

export default Footer
