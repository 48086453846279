import React, { useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'
import Title from '../Utilities/Title'
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const LifeAtAkcent = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699382424/Akcent%20HealthCare/Banner/life_at_akcent_rygxht.png" title="Life At Akcent" />

            <div className="life-at-akcent">

                <div className="life-events">
                    <Title title="events" />
                    <div className="life-events-body">

                    </div>
                </div>

                <div className="life-employee">
                    <Title title="employee glimpses" />

                    <div className="life-employee-body">
                        <div className="carousel">
                            <div class="carousel-wrapper">
                                <Carousel infiniteLoop useKeyboardArrows autoPlay showThumbs={false} showStatus={false} swipeable={false}	>
                                    <div className='carousel-body'>
                                        <svg id="Layer_1" enable-background="new 0 0 124 124" height="512" viewBox="0 0 124 124" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m78.557 70.278 15.551-15.924-21.398-3.266c-.54-.082-1.003-.426-1.233-.915l-9.477-20.173-9.477 20.173c-.23.489-.692.833-1.233.915l-21.398 3.266 15.551 15.924c.356.364.518.872.436 1.373l-3.648 22.349 18.971-10.477c.489-.27 1.108-.27 1.597 0l18.97 10.477-3.649-22.35c-.081-.5.081-1.008.437-1.372z" /><path d="m104.024 51.395-8.278-17.775-7.706 16.548 9.677 1.477c1.262.193 1.836 1.779.926 2.709l-17.167 17.578 2.301 14.092 11.308-4.934c.466-.201 1.022-.175 1.465.071l16.574 9.233-3.195-19.74c-.08-.497.08-1.002.432-1.366l13.639-14.087-18.743-2.887c-.542-.083-1.004-.428-1.233-.919z" /><path d="m42.524 71.931-17.167-17.577c-.908-.929-.337-2.516.926-2.709l9.677-1.477-7.706-16.548-8.278 17.775c-.229.491-.691.836-1.233.919l-18.743 2.887 13.639 14.088c.352.364.512.869.432 1.366l-2.845 17.577 16.366-7.142c.443-.192.949-.182 1.384.028l11.162 5.428z" /></g></svg>
                                        <h3>Lorem ipsum dolor sit amet</h3>
                                        <h6>Lorem ipsum</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo.</p>
                                    </div>

                                    <div className='carousel-body'>
                                        <svg id="Layer_1" enable-background="new 0 0 124 124" height="512" viewBox="0 0 124 124" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m78.557 70.278 15.551-15.924-21.398-3.266c-.54-.082-1.003-.426-1.233-.915l-9.477-20.173-9.477 20.173c-.23.489-.692.833-1.233.915l-21.398 3.266 15.551 15.924c.356.364.518.872.436 1.373l-3.648 22.349 18.971-10.477c.489-.27 1.108-.27 1.597 0l18.97 10.477-3.649-22.35c-.081-.5.081-1.008.437-1.372z" /><path d="m104.024 51.395-8.278-17.775-7.706 16.548 9.677 1.477c1.262.193 1.836 1.779.926 2.709l-17.167 17.578 2.301 14.092 11.308-4.934c.466-.201 1.022-.175 1.465.071l16.574 9.233-3.195-19.74c-.08-.497.08-1.002.432-1.366l13.639-14.087-18.743-2.887c-.542-.083-1.004-.428-1.233-.919z" /><path d="m42.524 71.931-17.167-17.577c-.908-.929-.337-2.516.926-2.709l9.677-1.477-7.706-16.548-8.278 17.775c-.229.491-.691.836-1.233.919l-18.743 2.887 13.639 14.088c.352.364.512.869.432 1.366l-2.845 17.577 16.366-7.142c.443-.192.949-.182 1.384.028l11.162 5.428z" /></g></svg>
                                        <h3>Lorem ipsum dolor sit amet</h3>
                                        <h6>Lorem ipsum</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo.</p>
                                    </div>

                                    <div className='carousel-body'>
                                        <svg id="Layer_1" enable-background="new 0 0 124 124" height="512" viewBox="0 0 124 124" width="512" xmlns="http://www.w3.org/2000/svg"><g><path d="m78.557 70.278 15.551-15.924-21.398-3.266c-.54-.082-1.003-.426-1.233-.915l-9.477-20.173-9.477 20.173c-.23.489-.692.833-1.233.915l-21.398 3.266 15.551 15.924c.356.364.518.872.436 1.373l-3.648 22.349 18.971-10.477c.489-.27 1.108-.27 1.597 0l18.97 10.477-3.649-22.35c-.081-.5.081-1.008.437-1.372z" /><path d="m104.024 51.395-8.278-17.775-7.706 16.548 9.677 1.477c1.262.193 1.836 1.779.926 2.709l-17.167 17.578 2.301 14.092 11.308-4.934c.466-.201 1.022-.175 1.465.071l16.574 9.233-3.195-19.74c-.08-.497.08-1.002.432-1.366l13.639-14.087-18.743-2.887c-.542-.083-1.004-.428-1.233-.919z" /><path d="m42.524 71.931-17.167-17.577c-.908-.929-.337-2.516.926-2.709l9.677-1.477-7.706-16.548-8.278 17.775c-.229.491-.691.836-1.233.919l-18.743 2.887 13.639 14.088c.352.364.512.869.432 1.366l-2.845 17.577 16.366-7.142c.443-.192.949-.182 1.384.028l11.162 5.428z" /></g></svg>
                                        <h3>Lorem ipsum dolor sit amet</h3>
                                        <h6>Lorem ipsum</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo. Lorem ipsum dolor sit amet consectetur adipisicing elit. Minus nihil, doloremque dolore porro eum a dolorum, et voluptates optio rerum molestiae pariatur quam facilis rem? Iusto reprehenderit harum voluptatum nemo.</p>
                                    </div>


                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="life-gallery">
                    <Title title="gallery" />
                    <div className="life-gallery-body">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />


                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254190/Akcent%20HealthCare/Values/2_psioqb.png" alt="" />

                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default LifeAtAkcent
