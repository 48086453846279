import React from 'react'

const Slider = () => {
    return (
        <div className='slider'>
            <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel" data-interval="4000">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <video autoPlay={true} loop muted>
                            <source src="https://res.cloudinary.com/ddwk3hbiu/video/upload/v1700221572/Akcent%20HealthCare/Slider/slider1_ojxqhq.mp4" />
                        </video>
                        {/* <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699081969/Akcent%20HealthCare/Slider/Slider_1_ytohp7.png" class="d-block w-100" alt="..." />
                        <h5>We deliver high quality products that patients can trust.</h5> */}
                    </div>
                    <div class="carousel-item">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699081972/Akcent%20HealthCare/Slider/Slider_2_tnrc08.png" class="d-block w-100" alt="Two employees from a Pharmaceutical Company discussing in a Lab" />
                        <h5>We aim to achieve the best quality standards in the Pharmaceutical industry in India.
                            <ul>
                                <li>Quality</li>
                                <li>Affordability</li>
                                <li>Availability</li>
                            </ul>
                        </h5>
                    </div>
                    <div class="carousel-item">
                        <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699081969/Akcent%20HealthCare/Slider/Slider_3_obtwdm.png" class="d-block w-100" alt="A globe and medicines representing 2000+ Distribution points across India" />
                        <h5>All India Distribution Network
                            <ul>
                                <li>2000+ Distribution Points</li>
                            </ul>
                        </h5>
                    </div>
                </div>
                <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    )
}

export default Slider
