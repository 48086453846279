import React from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import Banner from '../Utilities/Banner'

const ShopNow = () => {
    return (
        <div>
            <Header />
            <Banner src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1701851311/Akcent%20HealthCare/Banner/shop-now_banner_zmkrsf.png" title="Shop Now" />
            <Footer />
        </div>
    )
}

export default ShopNow
