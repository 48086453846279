import React, { useState, useEffect } from 'react'
import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import BCategory from '../Utilities/BCategory';
import LatestPosts from '../Utilities/LatestPosts';
import { useParams } from 'react-router-dom';
import fb from "../Firebase/firebase";
const DB = fb.firestore()
const Blogslist = DB.collection('Blogs');

const BlogDetails = () => {

    const { id } = useParams();
    const [blogs, Setblogs] = useState([]);
    Blogslist.doc(id).get().then((snapshot) => {
        const data = snapshot.data()
        Setblogs(data);
    });

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }, []);

    return (
        <div>
            <Header />
            <div className="blog">
                <div className="blog-body blog-details">
                    <div className="blog-body-content">
                        <div className="blog-card ">
                            <img src={blogs.Image} alt="" />
                            <div className="blog-card-text">
                                <span className='date'>{blogs.Date}</span>

                                <h1>{blogs.Title}</h1>
                                <div className='body' dangerouslySetInnerHTML={{ __html: blogs.Body }}></div>
                                {/* <div dangerouslySetInnerHTML={{ __html: blogs.Body }}></div> */}
                            </div>
                        </div>
                    </div>

                    {/* <div className="blog-body-text">
                        <div className="blog-body-categories">
                            <h4>Categories</h4>
                            <BCategory />
                        </div>
                        <div className="blog-body-categories">
                            <h4>Latest Posts</h4>
                            <LatestPosts />
                        </div>
                    </div> */}
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default BlogDetails
