import React from "react"

const CareerForm = () => {

    return (
        <div className='carrer-form'>
            <div className="modal fade" id="CarrerForm" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h3 className="modal-title fs-5" id="exampleModalLabel">Apply Now</h3>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <form id='career-form' method='POST' >
                                <div className="mb-3">
                                    <input type="text"
                                        className='form-control'
                                        name="Name"
                                        placeholder="Enter Name *"

                                        required />
                                </div>
                                <div className="mb-3">
                                    <input type="text"
                                        name="Mobile"
                                        className='form-control'
                                        placeholder="Enter Mobile *"
                                        required />
                                </div>

                                <div className="mb-3">
                                    <input style={{ textTransform: 'lowercase' }} type="email"
                                        name="Email"
                                        className='form-control'
                                        placeholder="Enter Email *"
                                        required />
                                </div>

                                <div className="mb-3">
                                    <input type="file"
                                        name="CV"
                                        className='form-control'
                                        placeholder="Enter Email *"
                                        required />
                                </div>

                                <select aria-label="Default select example"

                                    className="form-select mb-3"
                                    name='Job_Position'>
                                    <option value="job1">Job 1</option>
                                    <option value="job2">Job 1</option>
                                    <option value="job3">Job 1</option>

                                </select>

                                <textarea
                                    className="form-control mb-3"
                                    placeholder='Cover Letter *'
                                    name="Job_Letter"
                                    rows="4"
                                ></textarea>

                                <button type="submit" className="btn w-100" value="Submit">Submit</button>
                            </form>
                        </div>
                        <div className="modal-footer">
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default CareerForm
