import Header from '../Utilities/Header'
import Footer from '../Utilities/Footer'
import React, { useState, useEffect } from "react";
import Slider from '../Utilities/Slider';
import Title from '../Utilities/Title';
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { Link } from 'react-router-dom'
import { Helmet } from "react-helmet";
import Brands from '../Utilities/Brands';

const Home = () => {

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const [navBg, setNavBg] = useState("");
  const [top, setTop] = useState("");
  const [width, setWidth] = useState("");
  const [transform, setTransform] = useState("");
  const [borderRadius, setBorderRadius] = useState("");
  const [transition, setTransition] = useState("");

  const changeNavBg = () => {
    window.scrollY >= 200 ? setNavBg("#fff") : setNavBg("rgba(255,255,255,0.8");
    window.scrollY >= 200 ? setTop("0") : setTop("5%");
    window.scrollY >= 200 ? setWidth("100%") : setWidth("90%");
    window.scrollY >= 200 ? setTransform("none") : setTransform("translate(5%, 0)");
    window.scrollY >= 200 ? setBorderRadius("0") : setBorderRadius("10px");
    window.scrollY >= 200 ? setTransition(".5s all ease") : setTransition("none");
  }

  window.addEventListener('scroll', changeNavBg);

  const [display, setDisplay] = useState("none");
  const [rotateAbout, setRotateAbout] = useState("");
  const [displayAbout, setDisplayAbout] = useState("");

  const show = () => {
    setDisplay('flex');
  }

  const hide = () => {
    setDisplay('none');
  }


  const showAbout = () => {
    if (display == "none") {
      setDisplayAbout("flex");
      setRotateAbout("0deg");
    } else {
      setDisplayAbout("none");
      setRotateAbout("-45deg");
    }
  }



  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Akcent Healthcare - Top Pharmaceutical Company in India.</title>
        <link rel="canonical" href="https://www.akcenthealthcare.com/" />
        <meta name="description" content="Akcent Healthcare Pvt Ltd, a leading Pharmaceutical Company in India, is trusted for Excellence, Innovation, and Commitment to Health. Explore Our Range of Products and Services for a Healthier Tomorrow." />
      </Helmet>
      <h1 style={{ visibility: 'hidden' }}>Pharmaceutical company in Delhi NCR</h1>
      <div className='home-navbar' style={{ background: navBg, top: top, width: width, borderRadius: borderRadius, transform: transform, transition: transition }}>
        <div className='header' >
          <div className="header-logo">
            <Link to={"/"}>  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699011213/Akcent%20HealthCare/Logo/logo_transparent_i0ueir.png" alt="logo" /></Link>
          </div>
          <div className="header-links">
            <Link to={"/"}>Home </Link>
            <Link to={"/about-us"}>About Us </Link>
            <Link to={"/brand-portfolio"}>Brand Portfolio</Link>
            {/* <Link to={"/"}>Achievement</Link>
            <Link to={"/"}>Life At Akcent </Link> */}

            {/* <div className="dropdown">
              <Link to={"/about-us"}>About Us </Link>
              <div className="dropdown-content">
                <Link to={"/gallery"}>Gallery </Link>
                <Link to={"/news"}>News </Link>
                <Link to={"/press-release"}>Press Release </Link>
                <Link to={"/subsidiary"}>Subsidiary </Link>
                <Link to={"/investment-area"}>Investment Area </Link>
              </div>
            </div> */}
            <Link to={"/career"}>Career </Link>
            <Link to={"/blog"}>Blog</Link>
            <Link to={"/contact-us"}>Contact Us </Link>
            {/* <Link to={"/shop-now"}>Shop Now </Link> */}

          </div>
          <svg className='menu' onClick={show} id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m464.883 64.267h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m47.134 64.267c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 208.867c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 353.467c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m464.883 208.867h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m464.883 353.467h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.012-21.136-47.149-47.116-47.149z" /></svg>
        </div>
      </div>
      <div className='mobile' >
        <div className="header-logo">
          <Link to={"/"}>  <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699011213/Akcent%20HealthCare/Logo/logo_transparent_i0ueir.png" alt="logo" /></Link>
        </div>
        <svg className='menu' onClick={show} id="Layer_1" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><path d="m464.883 64.267h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m47.134 64.267c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 208.867c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m47.134 353.467c-25.989 0-47.134 21.144-47.134 47.133s21.145 47.133 47.134 47.133 47.133-21.144 47.133-47.133-21.144-47.133-47.133-47.133z" /><path d="m464.883 208.867h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.013-21.136-47.149-47.116-47.149z" /><path d="m464.883 353.467h-289.233c-25.98 0-47.117 21.137-47.117 47.149 0 25.98 21.137 47.117 47.117 47.117h289.232c25.98 0 47.117-21.137 47.117-47.117.001-26.012-21.136-47.149-47.116-47.149z" /></svg>

        <div className="header-mobile" style={{ display: display, background: '#fff', width: '70%', height: '100%' }}>
          <svg onClick={hide} className='cross' id="Layer_2_00000179618821480493002380000007792386261343661476_" enable-background="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" xmlns="http://www.w3.org/2000/svg"><g id="Icon"><path id="_38" d="m358.5 153.5c9.8 9.8 9.8 25.6 0 35.4l-67.2 67.1 67.2 67.2c9.8 9.8 9.8 25.6 0 35.4-9.8 9.8-25.6 9.8-35.4 0l-67.1-67.2-67.2 67.2c-9.8 9.8-25.6 9.8-35.4 0-9.8-9.8-9.8-25.6 0-35.4l67.2-67.2-67.2-67.2c-9.8-9.8-9.8-25.6 0-35.4 9.8-9.8 25.6-9.8 35.4 0l67.2 67.2 67.2-67.2c9.7-9.7 25.6-9.7 35.3.1z" /></g></svg>
          <div className="header-mobile-content">
            <Link to={"/"}>Home </Link>
            <Link to={"/about-us"}>About Us </Link>
            <Link to={"/brand-portfolio"}>Brand Portfolio</Link>
            {/* <Link to={"/"}>Achievement</Link>
            <Link to={"/"}>Life At Akcent </Link> */}
            <Link to={"/career"}>Career </Link>
            <Link to={"/blog"}>Blog</Link>
            <Link to={"/contact-us"}>Contact Us </Link>
          </div>
        </div>
      </div>

      <Slider />
      <div className='home'>

        <div className="about">

          <div className="about-img">
            <AnimationOnScroll animateIn="animate__slideInLeft">
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700221010/Akcent%20HealthCare/About%20Us/about_gbfom1.png" alt="Reception area of Akcent Healthcare" />
              {/* <div className="about-before"></div> */}
            </AnimationOnScroll>
          </div>

          <div className="about-text">
            <AnimationOnScroll animateIn="animate__slideInRight">

              <h2>About Us</h2>
              <p>Akcent Healthcare is a new-age pharmaceutical company that delivers cutting edge technology with advanced medical research to provide the best-in-class medicines for some of the most difficult and critical times for patients and medical professionals. Akcent Healthcare, headquarter office in Delhi, is professionally managed and works on the ethics of reliability, trustworthiness and ethics. Our formulation are USFDA, WHO and GMP certified, and we adhere to the highest levels of quality to ensure complete satisfaction from our patients and the larger medical community. Our product portfolio caters to a wide range of medical streams such as Cardiology, Neurology, Gastroenterology, Diabetology, Pulmonology, Dermatology, Ophthalmology, Gynecology, and many more. Our medicines are available with all leading chemist chains and Hospitals in India.</p>
            </AnimationOnScroll>
          </div>

        </div>

        <div className="brand-portfolio">
          <Title title="Brand Portfolio" />

          <div className="brand-portfolio-body">

            <Link to={"/brand-portfolio/#cardiovascular"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699017261/Akcent%20HealthCare/Brand%20Portfolio/Cardiovascular_l76pxt.png" alt="Cardiovascular" />
                <h5>Cardiovascular</h5>
              </AnimationOnScroll>
            </Link>

            <Link to={"/brand-portfolio/#neurology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018209/Akcent%20HealthCare/Brand%20Portfolio/Neurology_askwz6.png" alt="Neurology" />
                <h5>Neurology</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#orthopedics"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018211/Akcent%20HealthCare/Brand%20Portfolio/Orthopedics_lrbu3u.png" alt="Orthopedics" />
                <h5>Orthopedics</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#oncology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018210/Akcent%20HealthCare/Brand%20Portfolio/Oncology_ahf8kr.png" alt="Oncology" />
                <h5>Oncology</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#surgery"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018213/Akcent%20HealthCare/Brand%20Portfolio/Surgery_dhhok1.png" alt="Surgery" />
                <h5>Surgery</h5>
              </AnimationOnScroll>
            </Link>

            <Link to={"/brand-portfolio/#gynecology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018210/Akcent%20HealthCare/Brand%20Portfolio/Gynecology_ftqyiu.png" alt="Gynecology" />
                <h5>Gynecology</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#ent"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">

                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/ENT_mt59eb.png" alt="ENT" />
                <h5>ENT</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#nephrology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/Nephrology_mn8v2s.png" alt="Nephrology" />
                <h5>Nephrology</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#urology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">

                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018214/Akcent%20HealthCare/Brand%20Portfolio/Urology_t45kzt.png" alt="Urology" />
                <h5>Urology</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#physician"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">

                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018215/Akcent%20HealthCare/Brand%20Portfolio/Physician_ahpr1e.png" alt="Physician" />
                <h5>Physician</h5>
              </AnimationOnScroll>
            </Link>


            <Link to={"/brand-portfolio/#gastroenterology"} className="brand-portfolio-card">
              <AnimationOnScroll animateIn="animate__flipInX">

                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699018208/Akcent%20HealthCare/Brand%20Portfolio/Gastroenterology_colora.png" alt="Gastroenterology" />
                <h5>Gastroenterology</h5>
              </AnimationOnScroll>
            </Link>

          </div>

        </div>

        <div className="training">
          <Title title="Values" />
          <div className="training-body">
            <div className="training-img">
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254188/Akcent%20HealthCare/Values/3_y0setr.png" alt="6 wooden blocks placed in a pyramid-shape with healthcare signs" />
            </div>
            <div className="training-text">
              <p>As a corporation we believe in providing innovative solutions to
                healthcare problems and possess an entrepreneurial culture to build
                divisions and products for the same.We retain a special marketing
                process in the Indian pharma industry through our structure of
                customer dedicated marketing teams and comprehensive product
                portfolios.
              </p>
              <p>At the core of Akcent Healthcare India Pvt Ltd is a core value that resonates throughout the organization: "Stay with Akcent, Grow with Akcent". This core value encompasses the core of our company culture and acts as a guiding principle that supports our corporate ethics, employee relations and advocacy.</p>
              <Link to={"/about-us/#values"}>Read More</Link>
            </div>
          </div>
        </div>

        <div className="life">
          <Title title="Life At Akcent" />
          <div className="life-body">
            <div className="life-text">
              <p>We select highly skilled people and provide them with a professional
                work environment. Our multifaceted workplace provides excellent
                growth prospects through demanding positions with distinct duties
                and the chance to work and learn from a range of assignments. Every
                member of our team is empowered to succeed by our comprehensive
                programs for training, development, and career assistance.Our most
                precious resource is our workforce. One of our greatest assets is our
                multicultural workforce, which brings a wealth of knowledge from a
                variety of backgrounds and skill sets. We take great pride in the fact
                that our shared principles bind our diverse team together.</p>
            </div>
            <div className="life-img">
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1700545065/Akcent%20HealthCare/Values/2_n3lmgb.png" alt="A doctor and a patient discussing with each other" />
            </div>

          </div>
        </div>

        <div className="training">
          <Title title="Training And Development" />
          <div className="training-body">
            <div className="training-img">
              <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699254189/Akcent%20HealthCare/Values/1_hub5ry.png" alt="A person is providing training to the team" />
            </div>
            <div className="training-text">
              <p>Every human being is superior and special, in our opinion. We assist
                and mentor our employees in realizing their inherent potential for
                attaining success in both their personal and professional lives
                through training and development.Akcent believes that every
                employee's development is crucial to the company's overall success,
                particularly in terms of their ability to acquire new talents and develop
                soft skills.Akcent adheres to rigorous training and development
                programs with the goal of providing each product and service with
                expertise. Every field employee receives a promotion as they go
                closer to their objective, and at the same time, their abilities are
                polished to help them surpass both the company's objectives and
                their own performance.
              </p>
            </div>
          </div>
        </div>

        <Brands />

        <div className="home-blog">
          <Title title="Blog" />

          <div className="blog-body">
            <div className="blog-card">
              <AnimationOnScroll animateIn="animate__bounceIn">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699093946/Akcent%20HealthCare/Blog/blog1_fm3eck.jpg" alt="" />
                <div className="blog-text">
                  <div className='blog-text-after'></div>
                  <div className='blog-text-before'></div>
                  <a href="#">Whooping cough</a>
                  <p>Whooping cough (also known as 'pertussis') is a highly infectious infection of the lungs and airways. It is caused by bacteria. The disease is most serious in babies under the age of 12 months, particularly in the first few months.</p>
                  <a className='read' href="#">
                    Read More
                    <svg id="Capa_1" enable-background="new 0 0 512.002 512.002" height="512" viewBox="0 0 512.002 512.002" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path clip-rule="evenodd" d="m506.423 270.018-183.131 193.724c-3.835 4.086-9.188 6.406-14.792 6.41-2.601-.003-5.177-.499-7.594-1.46-7.786-3.071-12.893-10.601-12.866-18.97v-81.72h-254.44c-18.548-.021-33.579-15.052-33.6-33.6v-156.817c.023-18.547 15.053-33.577 33.6-33.6h254.44v-81.714c-.004-11.275 9.133-20.418 20.408-20.422 5.621-.002 10.993 2.314 14.852 6.401l183.128 193.72c7.434 7.873 7.432 20.179-.005 28.048z" fill-rule="evenodd" /></g></g></svg>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>

            <div className="blog-card">
              <AnimationOnScroll animateIn="animate__bounceIn">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699093947/Akcent%20HealthCare/Blog/blog2_sldnbd.jpg" alt="" />
                <div className="blog-text">
                  <div className='blog-text-after'></div>
                  <div className='blog-text-before'></div>
                  <a href="#">Natural Antibiotics</a>
                  <p>Antibiotics are medicine used to treat and prevent bacterial infections. When used properly, they can either kill or inhibit the growth of bacteria that cause problems like ear infections, stomachproblems and several skinissues.</p>
                  <a className='read' href="#">
                    Read More
                    <svg id="Capa_1" enable-background="new 0 0 512.002 512.002" height="512" viewBox="0 0 512.002 512.002" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path clip-rule="evenodd" d="m506.423 270.018-183.131 193.724c-3.835 4.086-9.188 6.406-14.792 6.41-2.601-.003-5.177-.499-7.594-1.46-7.786-3.071-12.893-10.601-12.866-18.97v-81.72h-254.44c-18.548-.021-33.579-15.052-33.6-33.6v-156.817c.023-18.547 15.053-33.577 33.6-33.6h254.44v-81.714c-.004-11.275 9.133-20.418 20.408-20.422 5.621-.002 10.993 2.314 14.852 6.401l183.128 193.72c7.434 7.873 7.432 20.179-.005 28.048z" fill-rule="evenodd" /></g></g></svg>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>

            <div className="blog-card">
              <AnimationOnScroll animateIn="animate__bounceIn">
                <img src="https://res.cloudinary.com/ddwk3hbiu/image/upload/v1699093949/Akcent%20HealthCare/Blog/blog3_tovxim.jpg" alt="" />
                <div className="blog-text">
                  <div className='blog-text-after'></div>
                  <div className='blog-text-before'></div>
                  <a href="#">Nutrition</a>
                  <p>Nutrition is the science that interprets the interaction of nutrients and other substances in food in relation to maintenance, growth, reproduction, health and disease of an organism.</p>
                  <a className='read' href="#">
                    Read More
                    <svg id="Capa_1" enable-background="new 0 0 512.002 512.002" height="512" viewBox="0 0 512.002 512.002" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path clip-rule="evenodd" d="m506.423 270.018-183.131 193.724c-3.835 4.086-9.188 6.406-14.792 6.41-2.601-.003-5.177-.499-7.594-1.46-7.786-3.071-12.893-10.601-12.866-18.97v-81.72h-254.44c-18.548-.021-33.579-15.052-33.6-33.6v-156.817c.023-18.547 15.053-33.577 33.6-33.6h254.44v-81.714c-.004-11.275 9.133-20.418 20.408-20.422 5.621-.002 10.993 2.314 14.852 6.401l183.128 193.72c7.434 7.873 7.432 20.179-.005 28.048z" fill-rule="evenodd" /></g></g></svg>
                  </a>
                </div>
              </AnimationOnScroll>
            </div>

          </div>
        </div>

      </div>
      <Footer />
    </div>
  )
}

export default Home
